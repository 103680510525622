@import "src/styles/abstracts";

.dots {
  @include animate(opacity visibility);
  @extend %listreset;

  opacity: 0;
  visibility: hidden;
  position: absolute;

  &.visible {
    opacity: 1;
    visibility: visible;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.loading {
  .dots {
    opacity: 1;
    visibility: visible;
  }
}

.dot {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: $white;
  transform: scale(0, 0);
  animation: superDots 2s linear infinite;

  .light & {
    background-color: $accent;
  }
}

.dot:nth-child(1) {
  animation-delay: 0;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
}

.dot:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes superDots {
  0%,
  100% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
}
