.form-modal-email {
  .react-select {
    &__control {
      min-height: 22px;

      &,
      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
        background-color: transparent;
        outline: none;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__input-container {
      margin: 0;
      padding: 0;
    }

    &__single-value {
      margin: 0;
    }

    &__indicators {
      display: none;
    }

    &__menu {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__option {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__option:hover,
    &__option--is-selected {
      color: #182c47;
    }
  }
}
