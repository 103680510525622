@import "src/styles/abstracts";

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  font-weight: 700;
  border-radius: 16px;
  border: 0;
  padding: 0 20px;
  background-color: $accent;
  color: $white;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s all;
  outline: none;
  white-space: nowrap;
  position: relative;

  &:hover,
  &:focus,
  &.loading {
    &:not(.invisible) {
      background-color: $green;
      color: $white;
    }
  }

  &:disabled {
    cursor: default;

    &:not(.invisible) {
      color: $text-light;
    }

    &:not(.empty):not(.invisible) {
      background-color: $border;
    }

    &.empty.btn {
      background-color: inherit;
    }
  }
}

.long {
  width: 100%;
}

.small {
  font-size: 14px;
  height: 24px;
}

.large {
  font-size: 24px;
}

.noPadding {
  padding: 0;
}

.fill-bright.btn {
  background-color: $accent-bright;
  color: $text-main;

  &:hover,
  &:focus,
  &.loading {
    background-color: $accent;
  }
}

.empty.btn {
  background-color: transparent;
  border: 1px solid $text-light;
  color: $text_main;

  &:hover,
  &:focus,
  &.loading {
    background-color: $text-light;
  }
}

.invisible {
  background-color: transparent;
  color: $text-main;

  &:hover,
  &:focus {
    .value {
      color: $green;
    }
  }

  &:disabled {
    .value {
      color: $text-light;
    }
  }

  .value {
    transition: inherit;
  }
}

.icon {
  font-size: 1.375rem;

  &:not(:only-child) {
    padding-right: 14px;
  }

  .warning & {
    color: $alert;
  }

  .warning:disabled & {
    color: inherit;
  }

  .accent & {
    color: $accent;
  }
}

.value {
  @include animate(opacity visibility);
  display: block;
}

.loading {
  composes: loading from "../LoadingDots/Dots.module.scss";

  span {
    opacity: 0;
    visibility: hidden;
  }
}
