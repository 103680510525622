@font-face {
    font-family: 'DM Sans';
    src: url('../assets/fonts/dmsans-bold-webfont.woff2') format('woff2'),
         url('../assets/fonts/dmsans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../assets/fonts/dmsans-italic-webfont.woff2') format('woff2'),
         url('../assets/fonts/dmsans-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../assets/fonts/dmsans-medium-webfont.woff2') format('woff2'),
         url('../assets/fonts/dmsans-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../assets/fonts/dmsans-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/dmsans-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}