$white: #ffffff;
$dark: #182c47;
$text-main: $dark;
$text-light: #a3abb5;
$accent-bright: #baff13;
$accent: #8adec6;
$yellow: #ddff80;
$alert: #d40033;
$border: #e3e5e8;
$highlight: #dbf0f0;
$bg: #f8f8f8;
$bg-green: #f3fcf9;
$bg-zebra: #fafafa;
$blue-dark: #0045a3;
$blue: #4076bf;
$blue-light: #a0bbdf;
$green-dark: #278b6f;
$green-middle: #32b38e;
$green: #60d2b1;
$green-light: #afe8d8;
$delimiter: #d6d9dd;

$font: "DM Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$scroll_track: $border;
$scroll_thumb: $accent;

$radius-sm: 16px;
$radius-xs: 12px;

//Layout
$sidebar_width: 264px;
$header-height: 118px;
$header-padding-top: 30px;
$header-padding-bottom: 24px;
$page-header-height: 72px;
$page-header-height-mob: 124px;
$upload-width-l: 214px;
$upload-width-s: 48px;
$margin-header-elements: 24px;
$margin-header-elements-mob: 16px;
