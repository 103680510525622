@import "src/styles/abstracts";

.backBtn {
  margin-left: -20px;
}

.static {
  overflow: auto;

  h1 {
    margin-bottom: 40px;
  }
}

.container {
  position: relative;
}

.content {
  @include m(mobile) {
    width: calc(100% - 320px);
  }
}

.row {
  padding: 40px 0;

  &:first-child {
    padding-top: 10px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border;
  }

  @include m(desktop) {
    display: flex;
  }

  h4 {
    margin: 0 0 4px 0;
  }

  a {
    @include animate(color);
    color: $blue-dark;
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }

  ul,
  ol {
    margin: 0 0 20px 0;
    padding-left: 20px;

    &:last-child {
      margin: 0;
    }
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tableWrap {
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: calc(100vw - 32px);
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}

table,
th,
td {
  border: 1px solid #e3e5e8;
  border-collapse: collapse;
}

table th {
  font-weight: 700;
}

table th,
table td {
  text-align: left;
  padding: 0.5em;
}

table td {
  vertical-align: top;
}

table td ul,
table td ol {
  margin: 0;
}

.italic-style {
  font-style: italic;
}

.title {
  line-height: 26px;
  margin: 0 30px 30px 0;

  @include m(desktop) {
    margin-bottom: 0;
    width: 30%;
    max-width: 30%;
    flex: 1 0 auto;
  }
}

.navigation {
  margin-bottom: 40px;

  @include m(mobile) {
    position: absolute;
    top: 0;
    right: 0;
    width: 196px;
  }

  a {
    cursor: pointer;
  }
}

.navWrap {
  @include scroll-container(10px);

  @include b(mobile) {
    height: auto !important;
  }

  @include m(mobile) {
    overflow-y: auto;
    position: fixed;
    width: 196px;
  }
}

.navList {
  @extend %listreset;
  font-weight: 700;
}

.navItem {
  margin-bottom: 16px;

  .active {
    color: $accent;

    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $accent;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
}

ul.listHeading {
  @extend %listreset;
  padding-left: 0;

  h4 {
    &::before {
      content: "";
      display: inline-block;
      margin: 0 8px 2px 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}
