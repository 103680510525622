$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon-union: "\e920";
$icon-arrow-back: "\e900";
$icon-reply: "\e915";
$icon-log-out: "\e90d";
$icon-bell: "\e901";
$icon-calendar: "\e902";
$icon-chevron-down: "\e903";
$icon-chevron-up: "\e904";
$icon-close: "\e905";
$icon-small-close: "\e91b";
$icon-eye: "\e908";
$icon-eye-off: "\e909";
$icon-grab: "\e90a";
$icon-maximize: "\e90e";
$icon-message-circle: "\e90f";
$icon-patients: "\e911";
$icon-user: "\e91e";
$icon-pie-chart: "\e912";
$icon-play: "\e913";
$icon-plus-circle: "\e914";
$icon-save: "\e916";
$icon-life-buoy: "\e90c";
$icon-search: "\e917";
$icon-paperclip: "\e910";
$icon-edit: "\e907";
$icon-send: "\e918";
$icon-image: "\e90b";
$icon-settings: "\e919";
$icon-trash: "\e91c";
$icon-share: "\e91a";
$icon-upload: "\e91d";
$icon-download: "\e906";
$icon-zap: "\e91f";

