@import 'icomoon-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fy4e6c') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fy4e6c') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fy4e6c##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-union {
  &:before {
    content: $icon-union; 
  }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-small-close {
  &:before {
    content: $icon-small-close; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off; 
  }
}
.icon-grab {
  &:before {
    content: $icon-grab; 
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize; 
  }
}
.icon-message-circle {
  &:before {
    content: $icon-message-circle; 
  }
}
.icon-patients {
  &:before {
    content: $icon-patients; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-life-buoy {
  &:before {
    content: $icon-life-buoy; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-zap {
  &:before {
    content: $icon-zap; 
  }
}

